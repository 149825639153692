import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebserviceService {

  //root = 'https://localhost:8443/';
  root = 'http://69.87.220.221:3000/';

  public isStatusClientChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _http:Http) { }

  ServerGet(url:string){
    return new Promise(resolve =>{
      this._http.get(url)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log("get server error");
        resolve(err);
      })
    });
  }

  ServerPost(url:string, body:any){
    return new Promise(resolve =>{
      this._http.post(url,body)
      .subscribe(data => {
        resolve(data.json());
      },err => {
        console.log("post ShowResult error");
        resolve(err);
      })
    });
  }

}
