import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { WaitinglistComponent } from './waitinglist/waitinglist.component';

import { WebserviceService } from './webservice.service';

import { RouterModule, Routes } from '@angular/router';

import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 

const appRoutes:Routes = [
  {
    path: '',
    //redirectTo: "waitinglist",
    //pathMatch: 'full'
    component: WaitinglistComponent
  },
  {
    path: 'waitinglist',
    component: WaitinglistComponent
  }

]

@NgModule({
  declarations: [
    AppComponent,
    WaitinglistComponent
  ],
  imports: [
  	RouterModule.forRoot(appRoutes),
    BrowserModule,
    HttpModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [WebserviceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
